
              @import "@/assets/css/variables.scss";
            

























































































































































































































// @import '@/assets/css/pages/rebateTransfer.scss';
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $text-primary;
  margin-bottom: 16px;
}

.transfer_fund {
  margin: 0 auto;
  max-width: 392px;

  .btn_wrapper {
    display: flex;
    justify-content: center;
  }
}
